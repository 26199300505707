const state = {
	info: {},
	product: [],
};

const mutations = {
	SET_PRODUCT(state, payload) {
		state.product = payload;
	},
	SET_INFO(state, payload) {
		state.info = payload;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
};
