<template>
<div>
  <router-view></router-view>
  <vue-ins-progress-bar></vue-ins-progress-bar>
</div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';

  @import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
  @import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
  @import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
  //@import "../node_modules/ag-grid-community/dist/styles/ag-theme-materialize.css";
</style>
