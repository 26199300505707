import {
	cibFacebook,
	cibTwitter,
	cibLinkedin,
	cibFlickr,
	cibTumblr,
	cibXing,
	cibGithub,
	cibStackoverflow,
	cibYoutube,
	cibDribbble,
	cibInstagram,
	cibPinterest,
	cibVk,
	cibYahoo,
	cibBehance,
	cibReddit,
	cibVimeo,
	cibCcMastercard,
	cibCcVisa,
	cibStripe,
	cibPaypal,
	cibGooglePay,
	cibCcAmex,
} from "@coreui/icons";
import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from "@coreui/icons";
import {
	cilArrowRight,
	cilBan,
	cilBasket,
	cilBell,
	cilCalculator,
	cilCalendar,
	cilCloudDownload,
	cilChartPie,
	cilCheck,
	cilChevronBottom,
	cilChevronTop,
	cilCheckCircle,
	cilCommentSquare,
	cilCursor,
	cilDrop,
	cilDollar,
	cilEnvelopeClosed,
	cilEnvelopeOpen,
	cilEuro,
	cilGlobeAlt,
	cilGrid,
	cilFile,
	cilJustifyCenter,
	cilLaptop,
	cilLayers,
	cilLightbulb,
	cilList,
	cilLocationPin,
	cilLockLocked,
	cilMagnifyingGlass,
	cilMoon,
	cilOptions,
	cilPencil,
	cilPeople,
	cilPuzzle,
	cilSettings,
	cilShieldAlt,
	cilSpeech,
	cilSpeedometer,
	cilStar,
	cilTask,
	cilUser,
	cilUserFemale,
	cilUserFollow,
	cilXCircle,
	cilTrash,
	cilPlus,
	cilReload,
	cilChevronDoubleDown,
} from "@coreui/icons";
import { logo } from "./logo";

export const iconsSet = Object.assign(
	{},
	{ logo },
	{
		cilArrowRight,
		cilBan,
		cilBasket,
		cilBell,
		cilCalculator,
		cilCalendar,
		cilCloudDownload,
		cilChartPie,
		cilCheck,
		cilChevronBottom,
		cilChevronTop,
		cilCheckCircle,
		cilCommentSquare,
		cilCursor,
		cilDrop,
		cilDollar,
		cilEnvelopeClosed,
		cilEnvelopeOpen,
		cilEuro,
		cilGlobeAlt,
		cilGrid,
		cilFile,
		cilJustifyCenter,
		cilLaptop,
		cilLayers,
		cilLightbulb,
		cilList,
		cilLocationPin,
		cilLockLocked,
		cilMagnifyingGlass,
		cilMoon,
		cilOptions,
		cilPencil,
		cilPeople,
		cilPuzzle,
		cilSettings,
		cilShieldAlt,
		cilSpeech,
		cilSpeedometer,
		cilStar,
		cilTask,
		cilUser,
		cilUserFemale,
		cilUserFollow,
		cilXCircle,

		cilTrash,
		cilPlus,
		cilReload,
		cilChevronDoubleDown,
	},
	{
		cifUs,
		cifBr,
		cifIn,
		cifFr,
		cifEs,
		cifPl,
	},
	{
		cibFacebook,
		cibTwitter,
		cibLinkedin,
		cibFlickr,
		cibTumblr,
		cibXing,
		cibGithub,
		cibStackoverflow,
		cibYoutube,
		cibDribbble,
		cibInstagram,
		cibPinterest,
		cibVk,
		cibYahoo,
		cibBehance,
		cibReddit,
		cibVimeo,
		cibCcMastercard,
		cibCcVisa,
		cibStripe,
		cibPaypal,
		cibGooglePay,
		cibCcAmex,
	}
);
