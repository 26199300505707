export const basuri =
	process.env.VUE_APP_STATUS === "prod"
		? process.env.VUE_APP_API_BASEURL_PROD
		: process.env.VUE_APP_API_BASEURL;

export const guard = function (access) {
	const { store } = require("../store/index");
	const permis = store.state.appinfo.permissions;

	//if (access == "all_access") return true;

	// console.log(store.state.appinfo.permissions);
	if (permis) {
		const key = "Pr4$eTy0 BoiL3rPlaT3";
		const aes256 = require("aes256");

		const permisDec = aes256.decrypt(key, permis);
		//const permisDec = permis

		if (permisDec.includes(access) || access == "all_access") {
			return true;
		} else return false;
	} else return false;
};

export const debounce = function (fn, delay) {
	let timeoutID = null;
	return function () {
		if (timeoutID) clearTimeout(timeoutID);

		const args = arguments;
		const self = this;

		timeoutID = setTimeout(function () {
			fn.apply(self, args);
		}, delay);
	};
};

export const getBase64 = function (file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
};

export const baseUrlImage = function (value) {
	if (!value) return "";
	if (value.includes("data:image")) return value;
	return basuri.replace(/\/\s*$/, "") + value;
};

export const insurance = [
	"none",
	"BPJS",
	"Allianz",
	"Prudential",
	"AIA Financial",
	"AXA Mandiri",
	"Indolife",
	"Sequis",
	"BNI Life",
	"Reliance",
	"Sinarmas",
	"FWD",
];

export const size = {
	80: { nama: "6-12 bln", alias: "3" },
	90: { nama: "1-2 thn", alias: "5" },
	100: { nama: "2-3 thn", alias: "7" },
	110: { nama: "3-4 thn", alias: "9" },
	120: { nama: "4-5 thn", alias: "11" },
	130: { nama: "5-6 thn", alias: "13" },
	140: { nama: "7-8 thn", alias: "15" },
	150: { nama: "8-9 thn", alias: "17" },
	160: { nama: "9-10 thn", alias: "19" },
	170: { nama: "10-11 thn", alias: "21" },
	180: { nama: "11-12 thn", alias: "23" },
	99: { nama: "All size", alias: "all" },
	15: { nama: "sepatu 15", alias: "sepatu 15" },
	16: { nama: "sepatu 16", alias: "sepatu 16" },
	17: { nama: "sepatu 17", alias: "sepatu 17" },
	18: { nama: "sepatu 18", alias: "sepatu 18" },
	19: { nama: "sepatu 19", alias: "sepatu 19" },
	20: { nama: "sepatu 20", alias: "sepatu 20" },
	21: { nama: "sepatu 21", alias: "sepatu 21" },
	22: { nama: "sepatu 22", alias: "sepatu 22" },
	23: { nama: "sepatu 23", alias: "sepatu 23" },
	24: { nama: "sepatu 24", alias: "sepatu 24" },
	25: { nama: "sepatu 25", alias: "sepatu 25" },
	26: { nama: "sepatu 26", alias: "sepatu 26" },
	27: { nama: "sepatu 27", alias: "sepatu 27" },
	28: { nama: "sepatu 28", alias: "sepatu 28" },
	29: { nama: "sepatu 29", alias: "sepatu 29" },
	30: { nama: "sepatu 30", alias: "sepatu 30" },
	31: { nama: "sepatu 31", alias: "sepatu 31" },
	32: { nama: "sepatu 32", alias: "sepatu 32" },
	33: { nama: "sepatu 33", alias: "sepatu 33" },
	34: { nama: "sepatu 34", alias: "sepatu 34" },
	35: { nama: "sepatu 35", alias: "sepatu 35" },
	36: { nama: "sepatu 36", alias: "sepatu 36" },
	37: { nama: "sepatu 37", alias: "sepatu 37" },
	38: { nama: "sepatu 38", alias: "sepatu 38" },
};

export const bulan = {
	1: "Januari",
	2: "Februari",
	3: "Maret",
	4: "April",
	5: "Mei",
	6: "Juni",
	7: "Juli",
	8: "Agustus",
	9: "September",
	10: "Oktober",
	11: "November",
	12: "Desember",
};

export const tahun = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

export const getDayName = function (dateStr, locale = "id-ID") {
	const date = new Date(dateStr);
	return date.toLocaleDateString(locale, { weekday: "long" });
};
