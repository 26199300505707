//import 'core-js/stable'
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import VueAxios from "./plugins/axios";
import store from "./store/index.js";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { debounce } from "@/plugins/utils";
import VueInsProgressBar from "vue-ins-progress-bar";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import VuetablePaginationBS from "../src/containers/VuetablePagination.vue";
import { Vuetable } from "vuetable-3";
// import { EllipsisLoader } from "vue-spinners-css";
import * as VueSpinnersCss from "vue-spinners-css";

Vue.config.performance = true;
Vue.use(CoreuiVue);

Vue.use(Toast, {
	transition: "Vue-Toastification__bounce",
	maxToasts: 20,
	newestOnTop: true,
	filterBeforeCreate: (toast, toasts) => {
		if (toasts.filter((t) => t.type === toast.type).length !== 0) {
			// Returning false discards the toast
			return false;
		}
		// You can modify the toast if you want
		return toast;
	},
	position: "top-center",
	timeout: 5000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.64,
	showCloseButtonOnHover: false,
	hideProgressBar: false,
	closeButton: "button",
	icon: true,
	rtl: false,
});
Vue.use(VueInsProgressBar, {
	position: "fixed",
	show: true,
	height: "5px",
});
Vue.use(VueSweetalert2);

Vue.use(VueAxios);
Vue.component("vuetable-pagination", VuetablePaginationBS);
Vue.component("vuetable", Vuetable);
Vue.use(VueSpinnersCss);
//Vue.prototype.$log = console.log.bind(console)

Vue.mixin({
	data() {
		return {
			permissionStore: store.state.appinfo.permissions,
			cssVuetable: {
				table: {
					tableClass: "table table-striped table-bordered table-hovered",
					loadingClass: "loading",
				},
				pagination: {
					wrapperClass: "pagination float-right",
					activeClass: "active",
					disabledClass: "disabled",
					pageClass: "page-item",
					linkClass: "page-link c-page-link-number",
					paginationClass: "pagination",
					paginationInfoClass: "float-left",
					dropdownClass: "form-control",
					icons: {
						first: "",
						prev: "",
						next: "",
						last: "",
					},
				},
			},
			httpoption: {
				baseURL:
					process.env.VUE_APP_STATUS === "prod"
						? process.env.VUE_APP_API_BASEURL_PROD
						: process.env.VUE_APP_API_BASEURL,
				headers: {
					Authorization: "Bearer " + store.state.appinfo.token,
				},
			},
			loadingTable: false,
		};
	},
	watch: {
		permissionStore: function (val) {
			//console.log(val);
			if (val != "") {
				this.$router.push({ name: "Dashboard" });
			} else {
				this.$router.push({ name: "Login" });
			}
		},
	},
	methods: {
		setNameToDefault() {
			let dataLocal = {
				name: "halo",
				token: null,
				role: null,
				username: null,
				email: null,
				permissions: "",
			};
			store.commit("appinfo/SET_NAME", dataLocal);
		},
		auth: debounce(async function () {
			try {
				const { data } = await this.$http.get("permissions");

				if (data.status == "fail") {
					this.$toast.error("Login please");
					this.setNameToDefault();
					this.$router.push({
						name: "Login",
					});
					return false;
				} else {
					store.commit("appinfo/SET_PERMISSIONS", data.data);
					return true;
				}
			} catch (e) {
				this.$toast.error("Login please");
				this.setNameToDefault();
				this.$router.push({
					name: "Login",
				});
				return false;
			}
		}, 500),

		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},
		transformData(d) {
			let transformed = {};

			transformed.pagination = {
				total: d.data.total,
				per_page: d.data.per_page,
				current_page: d.data.current_page,
				last_page: d.data.last_page,
				from: d.data.from,
				to: d.data.to,
			};

			transformed.mydata = d.data.data;

			return transformed;
		},
		onLoadingTable() {
			this.loadingTable = true;
		},
		onLoadSuccessTable(params) {
			this.loadingTable = false;
			if (params.data.status == "fail") {
				this.$toast.error(params.data.message);
			}
		},
		showDetail(id) {
			this.$refs.vuetable.toggleDetailRow(id);
		},
	},
	beforeRouteLeave: async function (to, from, next) {
		this.$insProgress.start();

		next();
	},
	beforeRouteEnter: async (to, from, next) => {
		next(async (vm) => {
			/*
      if (to.matched.some( (record) => {
        if(guard(store.state.appinfo.permissions, record.meta.permission)){
          this.$toast.success('silahkan')
        }else{
          this.$toast.success('silahkan')
        }
        //console.log(record.meta.permission)
      })) {

      }
      */

			if (to.matched.some(async (record) => record.meta.requiresAuth)) {
				/*
        if((from.name!='Login')){ 
          try{
            const {data} = await this.$http.get('permissions')

            if(data.status=='fail') {
              this.$toast.error('Login please')
              next({
                name:'Login'
              })
            }else{
              next()
            }
          }catch(e){
            this.$toast.error('Login please')
            next({
              name:'Login'
            })
          }
        }
        */

				if (
					to.name != "Login" &&
					to.name != "Page404" &&
					to.name != "Register" &&
					to.name != undefined
				) {
					vm.auth();
				}

				next();
			} else {
				next();
			}
			// access to component instance via `vm`
			vm.$insProgress.finish();
		});

		next();
	},
});

new Vue({
	el: "#app",
	router,
	store,
	icons,
	template: "<App/>",
	components: {
		App,
	},
});
