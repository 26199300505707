import ax from "axios";
import { basuri } from "./utils";
import MyVue from "vue";
import router from "@/router";
import SecureLS from "secure-ls";
import store from "@/store/index";

const ls = new SecureLS({ isCompression: false });

export const axios = ax.create({
	baseURL: basuri,
	headers: {
		Authorization: {
			toString() {
				let token = null;
				if (localStorage.getItem("pras-bp") != undefined) {
					token = JSON.parse(ls.get("pras-bp"))["appinfo"]["token"];
				}
				return token ? `Bearer ${token}` : null;
			},
		},
	},
});

axios.interceptors.request.use(
	function(config) {
		// Do something before request is sent
		//console.log(config);
		//MyVue.$insProgress.start()
		config.params = {
			// add your default ones
			t_: Date.now(),
			// spread the request's params
			...config.params,
		};
		return config;
	},
	function(error) {
		// Do something with request error
		console.log("Error", error.message);
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	function(response) {
		//MyVue.$insProgress.finish()
		return response;
	},
	function(error) {
		if (401 === error.response.status) {
			let dataLocal = {
				name: "halo",
				token: null,
				role: null,
				username: null,
				email: null,
				permissions: "",
			};

			store.commit("appinfo/SET_NAME", dataLocal);
			//console.log(error.response);
			MyVue.$toast.error(error.response.data.error);
			router.push({ name: "Login" });
			console.log("Error 401 bos");
		} else {
			return Promise.reject(error);
		}
	}
);

export default {
	install(Vue, options) {
		Vue.prototype.$http = axios;
	},
};
