const state = {
	name: "halo",
	token: null,
	role: null,
	username: null,
	email: null,
	permissions: "",
};

const mutations = {
	SET_NAME: (state, user) => {
		state.name = user.name;
		state.token = user.token;
		state.email = user.email;
		state.role = user.role;
		state.usernme = user.username;
		state.permissions = user.permissions;
	},
	SET_PERMISSIONS: (state, permissions) => {
		state.permissions = permissions;
	},
};

const actions = {};

const getters = {
	getPermissions: (state) => {
		return state.permissions;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
