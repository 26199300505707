import Vue from "vue";
import Router from "vue-router";
import { guard } from "@/plugins/utils";
import store from "@/store/index.js";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

const Colors = () => import("@/views/theme/Colors");
const Typography = () => import("@/views/theme/Typography");

const Charts = () => import("@/views/charts/Charts");
const Widgets = () => import("@/views/widgets/Widgets");

// Views - Components
const Cards = () => import("@/views/base/Cards");
const Forms = () => import("@/views/base/Forms");
const Switches = () => import("@/views/base/Switches");
const Tables = () => import("@/views/base/Tables");
const Tabs = () => import("@/views/base/Tabs");
const Breadcrumbs = () => import("@/views/base/Breadcrumbs");
const Carousels = () => import("@/views/base/Carousels");
const Collapses = () => import("@/views/base/Collapses");
const Jumbotrons = () => import("@/views/base/Jumbotrons");
const ListGroups = () => import("@/views/base/ListGroups");
const Navs = () => import("@/views/base/Navs");
const Navbars = () => import("@/views/base/Navbars");
const Paginations = () => import("@/views/base/Paginations");
const Popovers = () => import("@/views/base/Popovers");
const ProgressBars = () => import("@/views/base/ProgressBars");
const Tooltips = () => import("@/views/base/Tooltips");

// Views - Buttons
const StandardButtons = () => import("@/views/buttons/StandardButtons");
const ButtonGroups = () => import("@/views/buttons/ButtonGroups");
const Dropdowns = () => import("@/views/buttons/Dropdowns");
const BrandButtons = () => import("@/views/buttons/BrandButtons");

// Views - Icons
const CoreUIIcons = () => import("@/views/icons/CoreUIIcons");
const Brands = () => import("@/views/icons/Brands");
const Flags = () => import("@/views/icons/Flags");

// Views - Notifications
const Alerts = () => import("@/views/notifications/Alerts");
const Badges = () => import("@/views/notifications/Badges");
const Modals = () => import("@/views/notifications/Modals");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");

// Users
const Users = () => import("@/views/users/Users");
const User = () => import("@/views/users/User");

const Photo = () => import("@/views/photos/Photos");
const WinnerList = () => import("@/views/winner/WinnerList");
const Image = () => import("@/views/image/ImageForm");
const Role = () => import("@/views/acl/Role");
const Access = () => import("@/views/acl/Access");
const Permission = () => import("@/views/acl/Permission");

const ProductList = () => import("@/views/product/ProductList");
const CustomerList = () => import("@/views/customer/CustomerList");
const PoForm = () => import("@/views/purchase/PurchaseForm");
const PoList = () => import("@/views/purchase/PurchaseList");
const CheckStock = () => import("@/views/order/CheckStock");
const OrderList = () => import("@/views/order/OrderList");
const OrderForm = () => import("@/views/order/OrderForm");
const QrcodeByPo = () => import("@/views/qrcode/PrintByPo");
const Adjustment = () => import("@/views/adjustment/AdjustmentList");
const UserList = () => import("@/views/users/UserList");
const SearchOrderByProduct = () =>
	import("@/views/report/searchOrderByProduct");

const PatientList = () => import("@/views/patient/PatientList");
const PromoList = () => import("@/views/promo/PromoList");
const TransactionProductList = () =>
	import("@/views/transaction/TransactionProductList");

const TransactionCashList = () =>
	import("@/views/transaction/TransactionCashList");

const TransactionOrderForm = () =>
	import("@/views/transaction/TransactionOrderForm");
const TransactionOrderList = () =>
	import("@/views/transaction/TransactionOrderList");

Vue.use(Router);

const router = new Router({
	mode: "history", // https://router.vuejs.org/api/#mode
	linkActiveClass: "active",
	scrollBehavior: () => ({ y: 0 }),
	routes: configRoutes(),
});

router.beforeEach((to, from, next) => {
	let detectPersissionBoolean = true;
	for (let i = 0; i < to.matched.length; i++) {
		const el = to.matched[i].meta.permission;
		if (guard(el) || el == undefined) {
			detectPersissionBoolean = true;
		} else {
			detectPersissionBoolean = false;
			break;
		}
	}

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (store.state.appinfo.permissions == "") {
			next({ name: "Login" });
		} else {
			if (detectPersissionBoolean == false) {
				if (from.name == null) {
					next({ name: "Dashboard" });
				} else {
					next({ name: from.name });
				}
				Vue.$toast.error(
					"You not permitted to access this path (" + to.name + ")"
				);
			} else {
				next();
			}
		}
	} else {
		//next({name:'Login'})
		next();
	}

	/*
  to.matched.some( (record) => {
    if(guard(store.state.appinfo.permissions, record.meta.permission) ){ 
      console.log(record.meta.permission);    
      //next()
    }else{
      if(from.name==null){
        next({name:'Dashboard'})
      }else{
        next({name:from.name})
      }
      Vue.$toast.error('You not permitted to access this path ('+to.name+')')
    }
    //console.log(record.meta.permission)
    next()
  })
  */

	//next()
});

function configRoutes() {
	return [
		{
			path: "/",
			redirect: "/dashboard",
			name: "Home",
			meta: { requiresAuth: true, permission: "all_access" },
			component: TheContainer,
			children: [
				{
					path: "dashboard",
					name: "Dashboard",
					meta: { permission: "all_access" },
					component: Dashboard,
				},
				{
					path: "photos",
					name: "Photo List",
					meta: { permission: "photo_access" },
					component: Photo,
				},
				{
					path: "winner",
					name: "Winner List",
					meta: { permission: "all_access" },
					component: WinnerList,
				},
				{
					path: "image/form/:id?",
					name: "Image Form",
					meta: { permission: "all_access" },
					component: Image,
				},
				{
					path: "adjustment",
					name: "Penyesuaian",
					meta: { permission: "penyesuaian_access" },
					component: Adjustment,
				},

				{
					path: "cash",
					name: "Cash",
					meta: { permission: "all_access" },
					component: TransactionCashList,
				},

				{
					path: "master",
					redirect: "/master/product",
					name: "Master",
					meta: { permission: "master_access" },
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "product",
							name: "Product",
							meta: { permission: "all_access" },
							component: ProductList,
						},
						{
							path: "customer",
							name: "Customer",
							meta: { permission: "all_access" },
							component: CustomerList,
						},
						{
							path: "user",
							name: "User",
							meta: { permission: "all_access" },
							component: UserList,
						},
						{
							path: "patient",
							name: "Patient",
							meta: { permission: "all_access" },
							component: PatientList,
						},
						{
							path: "promo",
							name: "Promo",
							meta: { permission: "all_access" },
							component: PromoList,
						},
					],
				},

				{
					path: "transaction",
					redirect: "/transaction/product",
					name: "Transation",
					meta: { permission: "all_access" },
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "product",
							name: "Trans. Product",
							meta: { permission: "all_access" },
							component: TransactionProductList,
						},
					],
				},

				{
					path: "report",
					redirect: "/report/search-by-product",
					name: "Report",
					meta: { permission: "all_access" },
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "search-by-product",
							name: "Report Search By Product",
							meta: { permission: "all_access" },
							component: SearchOrderByProduct,
						},
					],
				},

				{
					path: "order",
					redirect: "/order",
					name: "Order",
					meta: { permission: "all_access" },
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "form",
							name: "Order Form",
							meta: { permission: "all_access" },
							component: OrderForm,
						},
						{
							path: "/order",
							name: "Order List",
							meta: { permission: "all_access" },
							component: OrderList,
						},
						{
							path: "stock",
							name: "Check Stock",
							meta: { permission: "all_access" },
							component: CheckStock,
						},
					],
				},

				{
					path: "po",
					redirect: "/po",
					name: "Purchase Order",
					meta: { permission: "all_access" },
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "form",
							name: "PO Form",
							meta: { permission: "all_access" },
							component: PoForm,
						},
						{
							path: "/po",
							name: "PO List",
							meta: { permission: "all_access" },
							component: PoList,
						},
					],
				},

				{
					path: "qrcode",
					redirect: "/qrcode",
					name: "QRCode",
					meta: { permission: "all_access" },
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "print-by-po",
							name: "Print By PO",
							meta: { permission: "all_access" },
							component: QrcodeByPo,
						},
						{
							path: "/qrcode",
							name: "Print By PO",
							meta: { permission: "all_access" },
							component: QrcodeByPo,
						},
					],
				},

				{
					path: "theme",
					redirect: "/theme/colors",
					name: "Theme",
					meta: { permission: "all_access" },
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "colors",
							name: "Colors",
							meta: { permission: "all_access" },
							component: Colors,
						},
						{
							path: "typography",
							name: "Typography",
							meta: { permission: "all_access" },
							component: Typography,
						},
					],
				},
				{
					path: "charts",
					name: "Charts",
					meta: { permission: "all_access" },
					component: Charts,
				},
				{
					path: "widgets",
					name: "Widgets",
					meta: { permission: "all_access" },
					component: Widgets,
				},
				{
					path: "users",
					meta: {
						label: "Users",
						permission: "all_access",
					},
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "",
							name: "Users",
							meta: { permission: "all_access" },
							component: Users,
						},
						{
							path: ":id",
							meta: {
								label: "User Details",
								permission: "all_access",
							},
							name: "User",
							component: User,
						},
					],
				},
				{
					path: "base",
					redirect: "/base/cards",
					name: "Base",
					meta: { permission: "all_access" },
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "cards",
							name: "Cards",
							meta: { permission: "all_access" },
							component: Cards,
						},
						{
							path: "forms",
							name: "Forms",
							meta: { permission: "all_access" },
							component: Forms,
						},
						{
							path: "switches",
							name: "Switches",
							meta: { permission: "all_access" },
							component: Switches,
						},
						{
							path: "tables",
							name: "Tables",
							meta: { permission: "all_access" },
							component: Tables,
						},
						{
							path: "tabs",
							name: "Tabs",
							meta: { permission: "all_access" },
							component: Tabs,
						},
						{
							path: "breadcrumbs",
							name: "Breadcrumbs",
							meta: { permission: "all_access" },
							component: Breadcrumbs,
						},
						{
							path: "carousels",
							name: "Carousels",
							meta: { permission: "all_access" },
							component: Carousels,
						},
						{
							path: "collapses",
							name: "Collapses",
							meta: { permission: "all_access" },
							component: Collapses,
						},
						{
							path: "jumbotrons",
							name: "Jumbotrons",
							meta: { permission: "all_access" },
							component: Jumbotrons,
						},
						{
							path: "list-groups",
							name: "List Groups",
							meta: { permission: "all_access" },
							component: ListGroups,
						},
						{
							path: "navs",
							name: "Navs",
							meta: { permission: "all_access" },
							component: Navs,
						},
						{
							path: "navbars",
							name: "Navbars",
							meta: { permission: "all_access" },
							component: Navbars,
						},
						{
							path: "paginations",
							name: "Paginations",
							meta: { permission: "all_access" },
							component: Paginations,
						},
						{
							path: "popovers",
							name: "Popovers",
							meta: { permission: "all_access" },
							component: Popovers,
						},
						{
							path: "progress-bars",
							name: "Progress Bars",
							meta: { permission: "all_access" },
							component: ProgressBars,
						},
						{
							path: "tooltips",
							name: "Tooltips",
							meta: { permission: "all_access" },
							component: Tooltips,
						},
					],
				},
				{
					path: "buttons",
					redirect: "/buttons/standard-buttons",
					name: "Buttons",
					meta: { permission: "all_access" },
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "standard-buttons",
							name: "Standard Buttons",
							meta: { permission: "all_access" },
							component: StandardButtons,
						},
						{
							path: "button-groups",
							name: "Button Groups",
							meta: { permission: "all_access" },
							component: ButtonGroups,
						},
						{
							path: "dropdowns",
							name: "Dropdowns",
							meta: { permission: "all_access" },
							component: Dropdowns,
						},
						{
							path: "brand-buttons",
							name: "Brand Buttons",
							meta: { permission: "all_access" },
							component: BrandButtons,
						},
					],
				},
				{
					path: "icons",
					redirect: "/icons/coreui-icons",
					name: "CoreUI Icons",
					meta: { permission: "all_access" },
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "coreui-icons",
							name: "Icons library",
							meta: { permission: "all_access" },
							component: CoreUIIcons,
						},
						{
							path: "brands",
							name: "Brands",
							meta: { permission: "all_access" },
							component: Brands,
						},
						{
							path: "flags",
							name: "Flags",
							meta: { permission: "all_access" },
							component: Flags,
						},
					],
				},
				{
					path: "notifications",
					redirect: "/notifications/alerts",
					name: "Notifications",
					meta: { permission: "all_access" },
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "alerts",
							name: "Alerts",
							meta: { permission: "all_access" },
							component: Alerts,
						},
						{
							path: "badges",
							name: "Badges",
							meta: { permission: "all_access" },
							component: Badges,
						},
						{
							path: "modals",
							name: "Modals",
							meta: { permission: "all_access" },
							component: Modals,
						},
					],
				},

				{
					path: "/",
					redirect: "/role",
					name: "ACL",
					meta: { permission: "acl_access" },
					component: {
						render(c) {
							return c("router-view");
						},
					},
					children: [
						{
							path: "role",
							name: "Role List",
							meta: { permission: "all_access" },
							component: Role,
						},
						{
							path: "access",
							name: "Access List",
							meta: { permission: "all_access" },
							component: Access,
						},
						{
							path: "permission",
							name: "Permission List",
							meta: { permission: "all_access" },
							component: Permission,
						},
					],
				},
			],
		},

		{
			path: "/pages",
			redirect: "/pages/404",
			name: "Pages",
			component: {
				render(c) {
					return c("router-view");
				},
			},
			children: [
				{
					path: "404",
					name: "Page404",
					meta: { permission: "all_access" },
					component: Page404,
				},
				{
					path: "500",
					name: "Page500",
					component: Page500,
				},
				{
					path: "register",
					name: "Register",
					component: Register,
				},
			],
		},

		{
			path: "/",
			redirect: "/login",
			name: "Login",
			meta: { permission: "all_access" },
			component: {
				render(c) {
					return c("router-view");
				},
			},
			children: [
				{
					path: "login",
					name: "Login",
					component: Login,
				},
			],
		},

		{ path: "*", component: Page404 },
	];
}

export default router;
